import {
  HospitalDTO, HospitalGISDTO, SearchHospitalsDTO,
} from 'karneyium';
import Axios from '../utils/http.config';

const BASE_URL = `/hospital`;

export class HospitalService {
  public static async getHospitalsByIds(hospitalIds: number[]): Promise<HospitalDTO[]> {
    return await Axios.post<{ hospitals: HospitalDTO[] }>(`${BASE_URL}`, hospitalIds)
      .then(response => response.data.hospitals);
  }

  public static async search(params: SearchHospitalsDTO = {}): Promise<HospitalDTO[]> {
    return await Axios.get<{ hospitals: HospitalDTO[] }>(`${BASE_URL}`, {
      params: {
        ...params,
      },
    })
      .then(response => response.data.hospitals);
  }

  public static async getAllHospitals(): Promise<HospitalDTO[]> {
    return await Axios.get<{ hospitals: HospitalDTO[] }>(`${BASE_URL}/allhospital`)
      .then(response => response.data.hospitals);
  }

  public static async getAllHospitalLayers(): Promise<HospitalGISDTO[]> {
    return await Axios.get<{ hospitals: HospitalGISDTO[] }>(`${BASE_URL}/searchForGIS`)
      .then(response => response.data.hospitals);
  }
}
