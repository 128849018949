import pluralize from 'pluralize';

type FormValue =
undefined |
null |
string |
number |
boolean |
Record<string, any> |
string[] |
number[] |
Array<Record<string, any>>;

interface PluralizeTextOptions {
  text: string;
  value: FormValue;
}

// Some bug in the pluralize library causes it to truncate the `s` in `this` if it had a space before it.
pluralize.addIrregularRule(` this`, ` these`);
pluralize.addIrregularRule(` was`, ` were`);
// Pluralize can't remove the singular article when pluralizing by default
pluralize.addPluralRule(`is a`, `are`);

export const pluralizeText = ({ text, value }: PluralizeTextOptions): string => {
  if (Array.isArray(value)) {
    return pluralize(text, value.length);
  }
  if (typeof value === `number`) {
    return pluralize(text, value);
  }
  return text;
};
