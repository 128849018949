import React, { useContext, useState } from 'react';
import Cookies from 'universal-cookie';
import { useAsyncEffect } from '@react-hook/async';
import { AsyncPageWrapper } from 'components';
import { AuthTokenDTO, LoginDTO } from 'karneyium';
import { AuthService } from 'services';
import { useNavigate } from 'react-router-dom';

interface IAuthContext {
  user: AuthTokenDTO;
  login: (data: LoginDTO, referrer?: string) => Promise<void>;
  logout: () => Promise<void>;
}

const checkIsAuthenticated = () => {
  const cookies = new Cookies();
  const spa_token: string = cookies.get(`spa_token`);
  return spa_token ? !!JSON.parse(spa_token) : false;
};

const bootstrapAppData = async () => {
  if (checkIsAuthenticated()) {
    return AuthService.whoAmI();
  }
  return null;
};

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);
AuthContext.displayName = `AuthContext`;

export const AuthProvider: React.FC = (props: any) => {
  const [ isAuthenticated, setIsAuthenticated ] = useState(checkIsAuthenticated());
  const { error, status, value: user } = useAsyncEffect(() => bootstrapAppData(), [ isAuthenticated ]);
  const navigate = useNavigate();

  const login = React.useCallback((form: LoginDTO, referrer?: string) =>
    AuthService.login(form)
      .then(() => {
        setIsAuthenticated(true);

        if (referrer) {
          navigate(referrer);
        }
      }),
  [ navigate ]);

  const logout = React.useCallback(() => {
    void AuthService.logout()
      .then(() => setIsAuthenticated(false));
  }, [ ]);

  const value = React.useMemo(() => ({ login, logout, user }), [ login, logout, user ]);

  return <AsyncPageWrapper status={status} error={error} height="100vh">
    <AuthContext.Provider value={value} {...props} />
  </AsyncPageWrapper>;
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export const useSettingValue = (id: number): string => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  if (context.user && context.user.settings) {
    const settingValue = context.user.settings.find((x) => x.id === id)?.value;
    return settingValue || ``;
  }
  return ``;
};
