import Graphic from '@arcgis/core/Graphic';
import FeatureTable from '@arcgis/core/widgets/FeatureTable';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer.js';
import MapView from '@arcgis/core/views/MapView';
import TextSymbol from '@arcgis/core/symbols/TextSymbol.js';

export interface TableColumn {
  fieldName: string;
  label: string;
  type: 'field' | 'group'; // Adjust as per your column template needs
  // Add more properties if necessary
}

export class MapHelperService {
  private data: __esri.Collection<Graphic> | null = null;
  private featureTableData: Map<string, TableColumn[]> = new Map();

  // Constructor is implicitly public
  public constructor() {
    // Initialize FeatureTableData for various layers
    this.initializeFeatureTableData();
  }

  private initializeFeatureTableData(): void {
    // Example: Initialize columns for Hospitals layer
    const hospitalColumns: TableColumn[] = [
      { fieldName: `objectid`, label: `Hospital ID`, type: `field` },
      { fieldName: `hospital_id`, label: `Hospital Name`, type: `field` },
      { fieldName: `hospital_type`, label: `Hospital Type`, type: `field` },
      { fieldName: `specialties_provided`, label: `Specialized Services`, type: `field` },
      { fieldName: `patient_population_size`, label: `Patient Population Size`, type: `field` },
      { fieldName: `number_of_beds`, label: `Number of Beds`, type: `field` },
      { fieldName: `outpatient_facilities`, label: `Outpatient Facilities`, type: `field` },
      { fieldName: `ehr_integration_level`, label: `EHR Integration Level`, type: `field` },
      { fieldName: `geographic_location`, label: `Geographic Location`, type: `field` },
      { fieldName: `clinical_trial_history`, label: `Clinical Trial History`, type: `field` },
      { fieldName: `patient_support_programs`, label: `Patient Support Programs`, type: `field` },
      { fieldName: `telehealth_capabilities`, label: `Telehealth Capabilities`, type: `field` },
      { fieldName: `dedicated_research_staff`, label: `Dedicated Research Staff`, type: `field` },
      { fieldName: `community_outreach_program`, label: `Community Outreach Program`, type: `field` },
    ];

    // Define columns for Claims layer
    const claimsColumns: TableColumn[] = [
      { fieldName: `date`, label: `date`, type: `field` },
      { fieldName: `age`, label: `age`, type: `field` },
      { fieldName: `sex`, label: `sex`, type: `field` },
      { fieldName: `census_tract_id`, label: `census_tract_id`, type: `field` },
      { fieldName: `disease_name`, label: `disease_name`, type: `field` },
      { fieldName: `disease_id`, label: `disease_id`, type: `field` },
    ];

    const krogerPharmaciesColumns: TableColumn[] = [
      { fieldName: `objectid`, label: `ObjectID`, type: `field` },
      { fieldName: `user_store_name_banner`, label: `Store Name/Banner`, type: `field` },
      { fieldName: `user_store_address`, label: `Store Address`, type: `field` },
      { fieldName: `user_store_city`, label: `Store City`, type: `field` },
      { fieldName: `user_store_state_code`, label: `Store State Code`, type: `field` },
      { fieldName: `user_store_zip_code`, label: `Store Zip Code`, type: `field` },
    ];

    const krogerLittleClinicsColumns: TableColumn[] = [
      { fieldName: `objectid`, label: `ObjectID`, type: `field` },
      { fieldName: `user_region`, label: `REGION`, type: `field` },
      { fieldName: `user_clinic_name`, label: `CLINIC_NAME`, type: `field` },
      { fieldName: `user_address`, label: `ADDRESS`, type: `field` },
      { fieldName: `user_city`, label: `CITY`, type: `field` },
      { fieldName: `user_state`, label: `STATE`, type: `field` },
      { fieldName: `user_zip`, label: `ZIP`, type: `field` },
      { fieldName: `user_f__of_rooms`, label: `# OF ROOMS`, type: `field` },
      { fieldName: `user_staffing`, label: `Staffing`, type: `field` },
      { fieldName: `user_pharmacy_access`, label: `Pharmacy Access`, type: `field` },
      { fieldName: `user_physical_exam_equipment`, label: `Physical Exam Equipment`, type: `field` },
      { fieldName: `user_vital_monitoring_equipment`, label: `Vital Monitoring Equipment`, type: `field` },
      { fieldName: `user_routine_medical_supplies`, label: `Routine Medical Supplies`, type: `field` },
      { fieldName: `user_biospecimen_collection_sup`, label: `Biospecimen Collection Supplies`, type: `field` },
      { fieldName: `user_specimen_processing_area`, label: `Specimen Processing Area`, type: `field` },
      { fieldName: `user_dry_ice___cryogenic_storag`, label: `Dry Ice & Cryogenic Storage`, type: `field` },
      { fieldName: `user_sharps_disposal`, label: `Sharps Disposal`, type: `field` },
      { fieldName: `user_investigational_product_ad`, label: `Investigational Product Administration`, type: `field` },
      { fieldName: `user_product_maintenance_equipm`, label: `Product Maintenance Equipment`, type: `field` },
      { fieldName: `user_calibration___monitoring`, label: `Calibration & Monitoring`, type: `field` },
      { fieldName: `user_locked_storage_for_equipme`, label: `Locked Storage for Equipment`, type: `field` },
      { fieldName: `user_study_file_storage`, label: `Study File Storage`, type: `field` },
      { fieldName: `user_administrative_office_equi`, label: `Administrative Office Equipment`, type: `field` },
    ];
    const federallyQualifiedHealthCentersColumns: TableColumn[] = [
      { fieldName: `objectid`, label: `ObjectID`, type: `field` },
      { fieldName: `health_center_type`, label: `Health Center Type`, type: `field` },
      { fieldName: `site_name`, label: `Site Name`, type: `field` },
      { fieldName: `site_address`, label: `Site Address`, type: `field` },
      { fieldName: `site_city`, label: `Site City`, type: `field` },
      { fieldName: `site_state_abbreviation`, label: `Site State Abbreviation`, type: `field` },
      { fieldName: `site_postal_code`, label: `Site Postal Code`, type: `field` },
      { fieldName: `site_telephone_number`, label: `Site Telephone Number`, type: `field` },
      { fieldName: `site_web_address`, label: `Site Web Address`, type: `field` },
      { fieldName: `operating_hours_per_week`, label: `Operating Hours per Week`, type: `field` },
      /* eslint-disable max-len */
      { fieldName: `health_center_service_delivery_`, label: `Health Center Service Delivery Site Location Setting Description`, type: `field` },
      { fieldName: `health_center_location_type_des`, label: `Health Center Location Type Description`, type: `field` },
      { fieldName: `health_center_type_description`, label: `Health Center Type Description`, type: `field` },
      { fieldName: `parent_organization`, label: `Parent Organization`, type: `field` },
      { fieldName: `state_name`, label: `State Name`, type: `field` },
    ];
    this.featureTableData.set(`Hospitals`, hospitalColumns);
    this.featureTableData.set(`Claims`, claimsColumns);
    this.featureTableData.set(`Kroger Pharmacies`, krogerPharmaciesColumns);
    this.featureTableData.set(`Kroger Little Clinics`, krogerLittleClinicsColumns);
    this.featureTableData.set(`Federally Qualified Health Centers`, federallyQualifiedHealthCentersColumns);

    // Add more layers as needed using this.featureTableData.set('LayerName', columnsArray);
    // Example:
    // this.featureTableData.set('Schools', schoolColumns);
  }

  public GetFeatureTable(layername: string, elementid: string, layer: any): FeatureTable | null {
    const columns = this.featureTableData.get(layername);

    if (!columns) {
      console.error(`Column data not found for layer: ${layername}`);
      return null;
    }

    const columnTemplates: TableColumn[] = columns.map(column => ({
      fieldName: column.fieldName,
      label: column.label,
      type: `field`, // Assuming all columns are 'field' type
    }));

    return new FeatureTable({
      container: document.getElementById(elementid) as HTMLElement,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      layer,
      tableTemplate: {
        columnTemplates,
      },
    });
  }

  public getData(): __esri.Collection<Graphic> | null {
    return this.data;
  }

  public setSketchData(tempData: __esri.Collection<Graphic>): void {
    this.data = tempData;
  }

  private getLayerFilterExpression(
    mapView: MapView | undefined,
    layerTitle: string,
    fieldMappings: { [key: string]: RegExp },
  ): string {
    let whereClause = ``;

    if (mapView) {
      const layer = mapView.map.allLayers.find((x) => x.title === layerTitle) as FeatureLayer;
      if (layer) {
        whereClause = layer.definitionExpression;
      }
    }

    if (whereClause && whereClause !== ``) {
      const tempWhereData = whereClause.split(`AND`);
      let newExpression = ``;

      tempWhereData.forEach((x, index) => {
        if (index > 0) {
          newExpression += ` AND `;
        }
        const tempData = x.trim();

        for (const [ field, regex ] of Object.entries(fieldMappings)) {
          if (tempData.includes(field)) {
            const fieldData = tempData.split(regex);
            newExpression += `${fieldData[0].trim()} ${fieldData[1]} ${fieldData[2].trim()}`;
            break;
          }
        }
      });

      whereClause = newExpression;
    }

    return whereClause;
  }

  public getClaimLayerFilterExpresion(mapView: MapView | undefined): string {
    let where_Clause = ``;
    if (mapView)
    {
      const claimsLayer = mapView.map.layers.find((x) => x.title === `Claims`) as FeatureLayer;
      if (claimsLayer) {
        where_Clause = claimsLayer.definitionExpression;
      }
    }
    if (where_Clause && where_Clause !== ``) {
      const tempWhereData = where_Clause.split(`AND`);
      let newExpression = ``;
      if (tempWhereData.length > 0) {
        tempWhereData.forEach((x, index) => {
          if (index > 0) {
            newExpression += ` AND `;
          }
          const tempData = x.trim();
          if (tempData.startsWith(`age`)) {
            const ageData = tempData.split(/ /g);
            newExpression += `${ageData[0]} ${ageData[1]} ${ageData[2]}`;
          }
          else if (tempData.startsWith(`sex`)) {
            const genderData = tempData.split(/ /g);
            newExpression += `${genderData[0]} ${genderData[1]} ${genderData[2]}`;
          }
          else if (tempData.startsWith(`disease_name`)) {
            const diseaseData = tempData.split(/(=|<|>)/);
            newExpression += `${diseaseData[0].trim()} ${diseaseData[1]} ${diseaseData[2].trim()}`;
          }
        });
        where_Clause = newExpression;
      }
    }

    return where_Clause;
  }

  public getKrogerPharmaciesLayerFilterExpresion(mapView: MapView | undefined): string {
    return this.getLayerFilterExpression(mapView, `Kroger Pharmacies`, {
      user_store_state_code: / /g,
    });
  }

  public getKrogerLilClinicsLayerFilterExpresion(mapView: MapView | undefined): string {
    return this.getLayerFilterExpression(mapView, `Kroger Little Clinics`, {
      user_region: / /g,
      user_state: / /g,
    });
  }

  public getFQHCFilterExpresion(mapView: MapView | undefined): string {
    return this.getLayerFilterExpression(mapView, `Federally Qualified Health Centers`, {
      health_center_type_description: /(=|<|>)/,
      parent_organization: /(=|<|>)/,
      state_name: /(=|<|>)/,
    });
  }

  public createGraphicsLayer(id: string, title: string, listMode: any = `hide`): GraphicsLayer {
    const graphicLayer = new GraphicsLayer({
      id,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      listMode,
      title,
    });
    return graphicLayer;
  }

  public createMarkerSymbol(url: string): __esri.PictureMarkerSymbolProperties {
    const symbol = {
      height: `32px`,
      type: `picture-marker`,
      url,
      width: `24px`,
    } as __esri.PictureMarkerSymbolProperties;

    return symbol;
  }

  /* eslint-disable max-len */
  public createGraphicSearchLayer(label: string, geometry: __esri.GeometryProperties, attributes: {[key: string]: string}): Graphic {
    const labelGraphic = new Graphic({
      attributes,
      geometry,
      symbol: new TextSymbol({
        color: `white`,
        font: {
          family: `sans-serif`,
          size: 10,
          weight: `bold`,
        },
        haloColor: `black`,
        haloSize: 1,
        text: label,
        yoffset: 15,
      }),
    });

    return labelGraphic;
  }

  public async featureTableFilterData(layer: FeatureLayer, field: keyof Record<string, any>) {
    const uniqueAttr = new Set<string>();
    const query = layer.createQuery();
    query.where = `1=1`;
    query.outFields = [ `*` ];

    const response = await layer.queryFeatures(query);
    response.features.forEach((item: { attributes: Record<string, any> }) => {
      const attr = item.attributes[field] as string;
      if (!uniqueAttr.has(attr)) {
        uniqueAttr.add(attr);
      }
    });

    return Array.from(uniqueAttr).sort();
  }
}

