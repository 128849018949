import {
  IAudited,
  PatientDTO,
  SearchPatientsDTO,
} from 'karneyium';
import { AxiosResponse } from 'axios';
import Axios from '../utils/http.config';

const BASE_URL = `/patient`;

export class PatientService {
  public static async getPatientById(id: number): Promise<IAudited<PatientDTO>> {
    return await Axios.get<{ patient: IAudited<PatientDTO> }>(`${BASE_URL}/${id}`)
      .then(response => response.data.patient);
  }

  public static async getPatientImageByID(id: number): Promise<string> {
    return await Axios.get(`${BASE_URL}/image/${id}`, { responseType: `blob` })
      .then((response: AxiosResponse<Blob>) => {

        const url = URL.createObjectURL(response.data);
        return url;
      });
  }

  public static async create(patient: PatientDTO): Promise<AxiosResponse> {
    return await Axios.post(`${BASE_URL}`, patient);
  }

  public static async search(params: SearchPatientsDTO = {}): Promise<Array<IAudited<PatientDTO>>> {
    return await Axios.get<{ patients: Array<IAudited<PatientDTO>> }>(`${BASE_URL}`, {
      params: {
        relations: [ `creator` ],
        ...params,
      },
    })
      .then(response => response.data.patients);
  }

  public static bulkCreate(data: FormData): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/bulk`,
      data,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });
  }
}
