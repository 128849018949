import CryptoJS from 'crypto-js';
const secretkey = `c28c9a82e60e09d78e5e002934ac7c9da091837721879bee7c69b0bf2a6b4922`;
export function Decrypt(encryptedText: string): string {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedText, secretkey);
  return decryptedBytes.toString(CryptoJS.enc.Utf8);
}

export function Encrypt(plainText: string): string {
  return CryptoJS.AES.encrypt(plainText, secretkey).toString();
}
