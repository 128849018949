import React, { useCallback, useEffect, useState } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  GroupingState,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import cn from 'classnames';
import { CButton, CCol, CFormCheck, CFormSelect, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Table as BTable, Spinner } from 'react-bootstrap';
import {
  faLongArrowDown,
  faLongArrowUp,
  feChevronLeft,
  feChevronRight,
} from 'assets/icons';
import 'scss/table.scss';
import { useErrorHandler } from 'react-error-boundary';
import { useAsync } from '@react-hook/async';
import { IPaginatedResponse } from 'karneyium';
import { useDebounce } from '@react-hook/debounce';
import duration from 'parse-duration';
import ReactPaginate from 'react-paginate';
import { isEqual } from 'lodash';
import { useBreakpoint } from '../../hooks';
import { FullPageSpinner } from '../Spinner';
import { ColumnFilter } from './filters';

interface TableProps<T extends Record<string, any>> {
  columns: Array<ColumnDef<T, any>>;
  data?: T[];
  getRowProps?: (row: Row<T>) => { [key: string]: any };
  responsive?: boolean | `sm` | `md` | `lg` | `xl`;

  // Searching
  noItemsText?: React.ReactNode;
  showRowsLoading?: boolean;
  showDataLoading?: boolean;

  // Selecting
  selectable?: boolean;
  onSelectionChange?: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  onRowClick?: (row: Row<T>) => void;

  // Pagination
  paginate?: boolean | `manual`;
  defaultPageSize?: number;
  startingPage?: number;
  dataProps?: { [key: string]: any };
  fetchData?: (props: any) => Promise<IPaginatedResponse<T> | T[]>;

  // Sorting
  sortable?: boolean | `manual`;
  sortBy?: SortingState;
  defaultGroupBy?: GroupingState;
}
const defaultPropGetter = () => ({});

export const Table: <T extends Record<string, any>>(props: TableProps<T>) =>
React.ReactElement<TableProps<T>> = ({
  columns,
  data = [],
  dataProps,
  defaultGroupBy = [],
  defaultPageSize = 15,
  fetchData,
  getRowProps = defaultPropGetter,
  noItemsText,
  onRowClick,
  onSelectionChange,
  paginate = false,
  responsive = false,
  selectable = false,
  showDataLoading = false,
  showRowsLoading = false,
  sortBy = [],
  sortable = false,
  startingPage = 0,
}) => {
  const handleError = useErrorHandler();
  const breakpoint = useBreakpoint();
  const [ isRowLoading, setIsRowLoading ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(false);
  const [ searchParams, setSearchParams ] = useDebounce<typeof dataProps>({}, duration(`0.5 seconds`));
  const [ tableData, setTableData ] = useState<typeof data>(data);
  const [ columnFilters, setColumnFilters ] = useState<ColumnFiltersState>([]);
  const [ sorting, setSorting ] = useState<SortingState>(sortBy);
  const [ pagination, setPagination ] = useState<PaginationState>({
    pageIndex: startingPage,
    pageSize: defaultPageSize,
  });
  const [ grouping, setGrouping ] = useState<GroupingState>(defaultGroupBy);
  const [ expanded, setExpanded ] = useState<ExpandedState>({});
  const [ rowSelection, setRowSelection ] = useState<RowSelectionState>({});
  const formatSortBy = useCallback((sort: typeof sortBy) => sort
    .map(({ desc, id }) => ({ [id]: desc ? `desc` : `asc` }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  []);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(rowSelection);
    }
  }, [ onSelectionChange, rowSelection ]);

  useEffect(() => {
    if (!fetchData) {
      setTableData(data);
    }
  }, [ data, fetchData ]);

  const [{ error, status, value: results }, callFetchData ] = useAsync(() => fetchData ? fetchData({
    ...dataProps,
    ...searchParams,
    ...sortable === `manual` && { sort: formatSortBy(sorting) },
    ...paginate === `manual` && { limit: pagination.pageSize, page: pagination.pageIndex },
  }) : Promise.resolve({ data: [], totalPages: 0 }));

  useEffect(() => {
    if (fetchData) {
      if (pagination.pageIndex !== startingPage) {
        setPagination((prev) => ({ ...prev, pageIndex: startingPage }));
      } else {
        void callFetchData();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ fetchData, dataProps, searchParams ]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: startingPage }));
    setColumnFilters([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dataProps ]);

  useEffect(() => {
    if (fetchData && paginate === `manual` && ![ `loading`, `idle` ].includes(status)) {
      void callFetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ fetchData, pagination.pageSize, pagination.pageIndex ]);

  useEffect(() => {
    if (fetchData && sortable === `manual` && ![ `loading`, `idle` ].includes(status)) {
      void callFetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ fetchData, sorting ]);

  useEffect(() => {
    if (!fetchData) {
      return;
    }

    switch (status) {
      case `loading`:
        setIsDataLoading(showDataLoading && showRowsLoading);
        setIsRowLoading(!showRowsLoading);
        break;
      case `idle`:
      case `error`:
        handleError(error);
        break;
      case `success`:
        setIsRowLoading(false);
        setIsDataLoading(false);
        setTableData(Array.isArray(results) ? results : results?.data || []);
        break;
      default:
        throw new Error(`Unhandled status: ${status}`);
    }
  }, [ error, fetchData, handleError, results, status, showRowsLoading, showDataLoading ]);

  useEffect(() => {
    handleError(error);
  }, [ error, handleError ]);

  const table = useReactTable({
    autoResetExpanded: false,
    columns: [
      ...selectable ? [{
        cell: ({ row }) =>
          <div className="px-1">
            <CFormCheck
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>,
        enableSorting: false,
        header: ({ table: t }) =>
          <CFormCheck
            {...{
              checked: t.getIsAllRowsSelected(),
              indeterminate: t.getIsSomeRowsSelected(),
              onChange: t.getToggleAllRowsSelectedHandler(),
            }}
          />,
        id: `selection`,
      }] as typeof columns : [],
      ...columns,
    ],
    data: tableData,
    enableRowSelection: selectable,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualFiltering: !!(fetchData && paginate === `manual`),
    manualPagination: !!(fetchData && paginate === `manual`),
    manualSorting: !!(fetchData && sortable === `manual`),
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    onGroupingChange: setGrouping,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    pageCount: fetchData ? !Array.isArray(results) ? results?.totalPages : undefined : undefined,
    state: {
      columnFilters,
      expanded,
      grouping,
      pagination,
      rowSelection,
      sorting,
    },
  });

  useEffect(() => {
    const newSearchParams = columnFilters
      .map(({ id, value }) => ({ [id]: value }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    if (!isEqual(newSearchParams, searchParams)) {
      setSearchParams(newSearchParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ columnFilters ]);

  useEffect(() => {
    table.toggleAllRowsExpanded(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ tableData ]);

  return <CRow>
    <CCol>
      {!!defaultGroupBy.length && <CRow>
        <CCol>
          <CButton onClick={() => { table.toggleAllRowsExpanded(); }}>
            {table.getIsAllRowsExpanded() ? `Collapse` : `Expand`} All
          </CButton>
        </CCol>
      </CRow>}
      <CRow>
        <CCol className={cn({
          'table-responsive': responsive && typeof responsive === `boolean`,
          'table-responsive-lg': responsive === `lg`,
          'table-responsive-md': responsive === `md`,
          'table-responsive-sm': responsive === `sm`,
          'table-responsive-xl': responsive === `xl`,
        })}>
          <div
            style={{
              minHeight: `500px`,
            }}>
            {isDataLoading && <div style={{ position: `absolute`, right: 10, top: 10 }}>
              <Spinner animation={`border`} />
            </div>}
            <BTable striped responsive
              style={{ flex: `1`, fontSize: `14px`, height: `100%`, width: `100%` }}
            >
              <thead>
                {table.getHeaderGroups().map(headerGroup =>
                  <React.Fragment key={headerGroup.id}>
                    <tr>
                      {headerGroup.headers.map(header =>
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ?
                            null :
                            <div
                              className={cn({
                                'align-content': header.column.getCanSort(),
                                'cursor-pointer': header.column.getCanSort(),
                                'select-none': header.column.getCanSort(),
                              })}
                              onClick={header.column.getToggleSortingHandler()}
                              onKeyDown={(e) => {
                                if (e.key !== `Tab`) {
                                  header.column.toggleSorting();
                                }
                              }}
                              role="button"
                              tabIndex={0}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {header.column.getCanSort() ? <span className="sort-arrows text-nowrap">{{
                                asc: <>
                                  <CIcon icon={faLongArrowUp} className="ml-2 text-dark" />
                                  <CIcon
                                    icon={faLongArrowDown}
                                    style={{ marginLeft: `-0.5rem` }}
                                  />
                                </>,
                                desc: <>
                                  <CIcon icon={faLongArrowUp} className="ml-2" />
                                  <CIcon
                                    icon={faLongArrowDown}
                                    className="text-dark"
                                    style={{ marginLeft: `-0.5rem` }}
                                  />
                                </>,
                              }[header.column.getIsSorted() as string] ?? <>
                                <CIcon icon={faLongArrowUp} className="ml-2" />
                                <CIcon
                                  icon={faLongArrowDown}
                                  style={{ marginLeft: `-0.5rem` }}
                                />
                              </>}
                              </span> : null}
                            </div>}
                        </th>)}
                    </tr>
                    {headerGroup.headers.some(header => header.column.getCanFilter()) &&
                      <tr key="filters">
                        {headerGroup.headers.map(header => <td key={`filter-${header.id}`} id={`filter-${header.id}`}>
                          {header.column.getCanFilter() ?
                            <ColumnFilter table={table} column={header.column} /> :
                            null}
                        </td>)}
                      </tr>}
                  </React.Fragment>)}
              </thead>
              <tbody data-testid="tableBody" >
                {isRowLoading ? <tr><td colSpan={table.getVisibleFlatColumns().length}><FullPageSpinner /></td></tr> :
                  table.getRowModel().rows.length || !noItemsText ?
                    table.getRowModel().rows.map(row => <tr
                      key={row.id}
                      onClick={() => onRowClick && onRowClick(row)}
                      {...getRowProps(row)}
                      {...(row.original as { id?: string })?.id ? {
                        'data-testid': `tableRow-${(row.original as unknown as { id: string }).id}`,
                      } : {}}
                    >
                      {row.getVisibleCells().map(cell => <td
                        key={cell.id}
                        {...cell.column.columnDef.testId ? { 'data-testid': cell.column.columnDef.testId } : {}}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>)}
                    </tr>) :
                    <tr>
                      <td colSpan={columns.length}>
                        <div className="text-center my-3">
                          <p>
                            {noItemsText}
                          </p>
                        </div>
                      </td>
                    </tr>}
              </tbody>
            </BTable>
          </div>
          { paginate && !isRowLoading &&
            <CRow>
              {table.getPageCount() > 0 && <CCol sm="9">
                <ReactPaginate
                  containerClassName="pagination"
                  activeClassName="active"
                  disabledClassName="disabled"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  breakLabel="..."
                  previousLabel={<CIcon icon={feChevronLeft} data-testid="previousPage" />}
                  nextLabel={<CIcon icon={feChevronRight} data-testid="nextPage" />}
                  onPageChange={({ selected }) => {
                    table.setPageIndex(selected);
                  }}
                  forcePage={pagination.pageIndex}
                  pageRangeDisplayed={
                    breakpoint === `lg` ? 8 :
                      breakpoint === `md` ? 5 :
                        breakpoint === `sm` ? 3 : 2
                  }
                  marginPagesDisplayed={
                    breakpoint === `lg` || breakpoint === `md` ? 3 :
                      breakpoint === `sm` ? 2 : 1
                  }
                  pageCount={table.getPageCount()}
                />
              </CCol>}
              <CCol sm="3">
                <CFormSelect
                  value={pagination.pageSize}
                  data-testid="pageSizeFilter"
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[ 5, 15, 25, 35, 45, 55 ].map(sizes =>
                    <option key={sizes} value={sizes}>
                      Show {sizes}
                    </option>)}
                </CFormSelect>
              </CCol>
            </CRow>}
        </CCol>
      </CRow>
    </CCol>
  </CRow>;
};
