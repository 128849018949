import { GetRolesDTO, RoleDTO } from 'karneyium';
import Axios from '../utils/http.config';

const BASE_URL = `/role`;

export class RoleService {
  public static get(params: GetRolesDTO = {}): Promise<RoleDTO[]> {
    return Axios.get<{ roles: RoleDTO[] }>(`${BASE_URL}`, {
      params,
    })
      .then(response => response.data.roles);
  }
}
