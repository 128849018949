import React, { useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import './scss/style.scss';
import 'leaflet/dist/leaflet.css';
import { UserDTO } from 'karneyium';
import { UserService } from './services/UserService';
import { socketConnection } from './utils';

const loading =
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>;

const AuthenticatedApp = React.lazy(() => import(`./views/entry/AuthenticatedApp`));
const UnauthenticatedApp = React.lazy(() => import(`./views/entry/UnauthenticatedApp`));

// export const generateSecretKey = async () => {
//   const key = await window.crypto.subtle.generateKey(
//     {
//       name: `AES-GCM`,
//       length: 256, // 256 bits
//     },
//     true, // Extractable
//     [ `encrypt`, `decrypt` ], // Key usages
//   );
//   const exportedKey = await window.crypto.subtle.exportKey(`raw`, key);
//   const secretKeyHex = Array.from(new Uint8Array(exportedKey))
//     .map(byte => byte.toString(16).padStart(2, `0`))
//     .join(``);
//   return secretKeyHex;
// };

const App: React.FC = () => {
  const { user } = useAuth();

  // const secretKey = generateSecretKey();
  // console.log(`Generated Secret Key:`, secretKey);

  useEffect(() => {
    socketConnection.on(`connect`, () => {
      console.log(`Connected`);
      console.log(socketConnection.id);
      if (user && socketConnection.id) {
        const updatedUserData: UserDTO = {
          email: user.email,
          firstName: user.firstName,
          id: user.id,
          lastName: user.lastName,
          name: user.name,
          sessionId: socketConnection.id,
        };
        // Call UserService.update with user ID and updated user data
        UserService.update(user.id, updatedUserData).then(() => {}).catch(() => {});
      }
    });

    return () => {
      socketConnection.off(`connect`);
      socketConnection.off(`chat`);
    };
  }, [ socketConnection ]);

  useEffect(() => {
    if (user && socketConnection.id) {
      const updatedUserData: UserDTO = {
        email: user.email,
        firstName: user.firstName,
        id: user.id,
        lastName: user.lastName,
        name: user.name,
        sessionId: socketConnection.id,
      };

      // Call UserService.update with user ID and updated user data
      UserService.update(user.id, updatedUserData).then(() => {}).catch(() => {});
    }
  }, [ user ]);

  return (
    <React.Suspense fallback={loading}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
};

export default App;
