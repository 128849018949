import { AxiosResponse } from 'axios';
import {
  IAudited,
  SearchUsersDTO,
  SendEmailDTO,
  UserDTO,
} from 'karneyium';
import Axios from '../utils/http.config';

const BASE_URL = `/user`;

export class UserService {
  public static get(params: SearchUsersDTO = {}): Promise<Array<IAudited<UserDTO>>> {
    return Axios.get<{ users: Array<IAudited<UserDTO>> }>(`${BASE_URL}`, {
      params: {
        relations: [ `organization`, `roles`, `audit`, `checkIns` ],
        ...params,
      },
    })
      .then(response => response.data.users);
  }

  public static GetAllOtherUser(): Promise<Array<IAudited<UserDTO>>> {
    return Axios.post<{ users: Array<IAudited<UserDTO>> }>(`${BASE_URL}/getallotheruser`)
      .then(response => response.data.users);
  }

  public static create(user: UserDTO): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}`, user);
  }

  public static async update(id: number, user: UserDTO): Promise<IAudited<UserDTO>> {
    const response = await Axios.put<{ user: IAudited<UserDTO> }>(`${BASE_URL}/${id}`, user);
    return response.data.user;
  }

  public static delete(id: number): Promise<AxiosResponse> {
    return Axios.delete(`${BASE_URL}/${id}`);
  }

  public static restore(id: number): Promise<AxiosResponse> {
    return Axios.patch(`${BASE_URL}/${id}`);
  }

  public static sendEmail(data: SendEmailDTO): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/send_email`, data);
  }

  public static bulkCreate(data: FormData): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/bulk`,
      data,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });
  }
}
